<template>
    <!-- Main Section -->
    <main class="drake-main">
        <div id="smooth-wrapper">
            <div id="smooth-content">
                <div class="left-sidebar">
                    <div class="sidebar-header d-flex align-items-center justify-content-between">
                        <img src="@/assets/images/logo.png" alt="Logo">
                        <span class="designation">Framer Designer & Developer</span>
                    </div>
                    <img class="me" src="@/assets/images/me.jpg" alt="Me">
                    <h2 class="email">hello@drake.design</h2>
                    <h2 class="address">Base in Los Angeles, CA</h2>
                    <p class="copyright">&copy; 2022 Drake. All Rights Reserved</p>
                    <ul class="social-profile d-flex align-items-center flex-wrap justify-content-center">
                        <li>
                            <a href=""><i class="lab la-twitter"></i></a>
                        </li>
                        <li>
                            <a href=""><i class="lab la-dribbble"></i></a>
                        </li>
                        <li>
                            <a href=""><i class="lab la-instagram"></i></a>
                        </li>
                        <li>
                            <a href=""><i class="lab la-github"></i></a>
                        </li>
                    </ul>
                    <a href="#" class="theme-btn">
                        <i class="las la-envelope"></i> Hire Me!
                    </a>
                </div>
                <router-view v-slot="{ Component }">
                    <keep-alive v-if="$route.meta.keepAlive">
                        <component :is="Component" />
                    </keep-alive>
                    <component :is="Component" v-if="!$route.meta.keepAlive" />
                </router-view>
            </div>
        </div>
    </main>
</template>